//QG 30/06/2024 MD

import React from "react";
import {
    ConfigurazioneModelloInterface,
    ConfigurazioneSezioneInterface,
    NomeSezioneInformazioneType,
    TipologiaMaterialeSupportatoEnum,
    TipoSezioneConfiguratoreType
} from "tici_commons";
import Materiali from "../../../../DatabaseData/Materiali";
import SezioneConfigurabile from "../../../GestioneModelli/SezioneConfigurabile";
import SwitchContainer from "../../../../Layout/SwitchContainer/SwitchContainer";
import SectionLayout from "../../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import ElementSelect from "../../../Selects/ElementSelect/ElementSelect";

export interface SelettoreDatoPrincipaleProps{
    sezioneConfigurabile: SezioneConfigurabile,
    configurazioneModello: ConfigurazioneModelloInterface,
    configurazioneSezione: ConfigurazioneSezioneInterface,
    valoreCorrente: string,
    datiSezioni?: NomeSezioneInformazioneType[],
    onDatoPrincipaleChange?: (datoPrincipale: string, tipoSezione: TipoSezioneConfiguratoreType) => void
}

export default class SelettoreDatoPrincipale extends React.Component<SelettoreDatoPrincipaleProps, any>{
    private _recuperaFamiglieEscluse(): string[]{
        const esito: string[] = [];

        esito.push(
            ...this.props.configurazioneModello.famiglieMaterialiEscluse.filter(famigliaEsclusa => !this.props.configurazioneSezione.famiglieMaterialiIncluse.includes(famigliaEsclusa))
        )

        return esito;
    }

    private _recuperaSpessoreMaterialePerSezione(): "spesso" | "sottile" | "*"{
        let esito: "spesso" | "sottile" | "*" = '*';

        const gruppo = this.props.configurazioneSezione.nomeGruppo;
        const sezioniDiGruppo =
            this.props.sezioneConfigurabile.sezioniConfigurabili
                .filter(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.nomeGruppo === gruppo)
                .filter(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.nomeReale !== this.props.configurazioneSezione.nomeReale);
        if(
            gruppo.length > 0 &&
            sezioniDiGruppo.length > 0 &&
            this.props.datiSezioni &&
            this.props.datiSezioni.length > 0
        ){
            const sezioneTarget = sezioniDiGruppo[0].configurazioneSezione.nomeReale;
            const datoSezioneTarget = this.props.datiSezioni
                .find(datoSezione => datoSezione[0] === sezioneTarget && datoSezione[1].tipoSezione === 'sezioneMateriale' && datoSezione[1].informazione.length > 0);
            if(datoSezioneTarget){
                esito = Materiali.GetSpessore(datoSezioneTarget[1].informazione);
            }
        }else{
            switch (this.props.configurazioneSezione.tipologiaMaterialeSupportato){
                case TipologiaMaterialeSupportatoEnum.SOTTILE:
                    esito = 'sottile';
                    break;
                case TipologiaMaterialeSupportatoEnum.SPESSO:
                    esito = 'spesso';
                    break;
                case TipologiaMaterialeSupportatoEnum.SOTTILE_SPESSO:
                    esito = '*';
                    break;
            }
        }

        return esito;
    }

    private _selettoreMateriale(){
        const materiali = Materiali.GetMateriali(this._recuperaSpessoreMaterialePerSezione(), ...this._recuperaFamiglieEscluse());
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel content={"Seleziona materiale:"} type={"medium"} alignment={"left"}/>
                <ElementSelect
                    label={"Seleziona"}
                    value={this.props.valoreCorrente}
                    elements={materiali}
                    blockMode={true}
                    maxHeight={500}
                    autocompleteMode={true}
                    onChange={v => this.props.onDatoPrincipaleChange && this.props.onDatoPrincipaleChange(v, this._tipoSezione())}/>
            </SectionLayout>
        );
    }

    private _selettoreLegno(){
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel content={"Seleziona il tipo di legno:"} type={"medium"} alignment={"left"}/>
                <ElementSelect
                    label={"Seleziona"}
                    value={this.props.valoreCorrente}
                    elements={Materiali.GetTipiLegno()}
                    blockMode={true}
                    maxHeight={500}
                    onChange={v => this.props.onDatoPrincipaleChange && this.props.onDatoPrincipaleChange(v, this._tipoSezione())}/>
            </SectionLayout>
        );
    }

    private _selettorePlex(){
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel content={"Seleziona il tipo di plex:"} type={"medium"} alignment={"left"}/>
                <ElementSelect
                    label={"Seleziona"}
                    value={this.props.valoreCorrente}
                    elements={Materiali.GetTipiPlex()}
                    blockMode={true}
                    maxHeight={500}
                    onChange={v => this.props.onDatoPrincipaleChange && this.props.onDatoPrincipaleChange(v, this._tipoSezione())}/>
            </SectionLayout>
        );
    }

    private _selettoreColore(){
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel content={"Seleziona il tipo di colore:"} type={"medium"} alignment={"left"}/>
                <ElementSelect
                    label={"Seleziona"}
                    value={this.props.valoreCorrente}
                    elements={Materiali.GetTipiColore()}
                    blockMode={true}
                    maxHeight={500}
                    onChange={v => this.props.onDatoPrincipaleChange && this.props.onDatoPrincipaleChange(v, this._tipoSezione())}/>
            </SectionLayout>
        );
    }

    private _tipoSezione(): TipoSezioneConfiguratoreType{
        let esito: TipoSezioneConfiguratoreType = 'sezioneMateriale';
        if(this.props.configurazioneSezione.sezioneLegnoFlag)
            esito = "sezioneLegno";
        if(this.props.configurazioneSezione.sezionePlexFlag)
            esito = "sezionePlex";
        if(this.props.configurazioneSezione.sezioneColoreBiancoNeroFlag)
            esito = "sezioneColore";
        return esito;
    }

    private _indiceVisualizzazioneSelettore(): number{
        return {
            sezioneMateriale: 0,
            sezioneLegno: 1,
            sezionePlex: 2,
            sezioneColore: 3
        }[this._tipoSezione() as string] || 0;
    }

    public render() {
        return (
            <SwitchContainer caseNumber={this._indiceVisualizzazioneSelettore()}>
                {this._selettoreMateriale()}
                {this._selettoreLegno()}
                {this._selettorePlex()}
                {this._selettoreColore()}
            </SwitchContainer>
        );
    }
}
