import React, {Fragment} from "react";
import {
    StorageData,
    StorageManagerContext,
    StorageManagerStatus
} from "../../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import MultiSelettore from "../../MultiSelettore/MultiSelettore";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import {SelectLine, TextLine} from "../../../Widgets/Configuratore/CComponents";
import {CartaAlternativa, ImmagineAmmessaEnum, Lavorazioni} from "tici_commons";
import Responsive2Col from "../../../Layout/Responsive2Col/Responsive2Col";
import Button from "../../../Core/Buttons/Button";
import ImagePreviewOverlay from "../../ImagePreviewOverlay/ImagePreviewOverlay";
import Modello from "../../../DatabaseData/Modello";
import ManagerRenderizzatoreModelli from "../../ManagerRenderizzatoreModelli/ManagerRenderizzatoreModelli";
import SezioneConfigurabile from "../../GestioneModelli/SezioneConfigurabile";
import {imageTypeSupported} from "../../../Utils/CommonFunctions";

export interface DADMIndexAzioneProps{
    immagini: StorageData[],
    onDelete?: (nomeImmagine: string) => void,
    modificheCartaExtra?: boolean,
    aggiungiCartaExtraSelezionati?: (cartaExtraSelezionata: string) => void,
    modificheLavorazioni?: boolean,
    aggiungiLavorazione?: (nomeImmagine: string, nomeLavorazione: string, datiAggiuntiviLavorazionePresenti?: boolean, datiAggiuntiviLavorazione?: string[]) => boolean
}

export interface DADMIndexAzioneState{
    cartaExtraSelezionata: string,
    lavorazioneSelezionata: string,
    nomeImmagineOverlay: string,
    sezioneSecondaria: SezioneConfigurabile
}

export default class DADMIndexAzione extends React.Component<DADMIndexAzioneProps, DADMIndexAzioneState>{
    private _sezionePrincipale: SezioneConfigurabile;
    private _sezioneSecondariaPossiedeDati = false;

    constructor(props: Readonly<DADMIndexAzioneProps> | DADMIndexAzioneProps) {
        super(props);
        this.state = {
            cartaExtraSelezionata: "",
            lavorazioneSelezionata: "",
            nomeImmagineOverlay: "",
            sezioneSecondaria: undefined
        }
    }

    public componentDidUpdate(prevProps: Readonly<DADMIndexAzioneProps>) {
        if(prevProps.immagini.length !== this.props.immagini.length){
            this.setState({lavorazioneSelezionata: "", cartaExtraSelezionata: "", sezioneSecondaria: undefined})
        }
    }

    /**
     * Gestisce l'aggiunta di carta extra
     * @private
     */
    private _aggiungiCartaExtraHandle(){
        this.props.aggiungiCartaExtraSelezionati && this.props.aggiungiCartaExtraSelezionati(this.state.cartaExtraSelezionata);
        this.setState({cartaExtraSelezionata: ""});
    }

    /**
     * Gestisce la rimozione dei fogli
     * @private
     */
    private _rimuoviFogliHandler(){
        if(this.props.onDelete){
            for(const immagine of this.props.immagini){
                this.props.onDelete(immagine.name);
            }
        }
        this.setState({cartaExtraSelezionata: ""});
    }

    /**
     * Controlla che il modello per la lavorazione selezionata esista oppure no
     * @private
     */
    private _modelloEsiste(): boolean{
        return Modello.ModelExist(this.state.lavorazioneSelezionata);
    }

    /**
     * Aggiunge le lavorazioni
     * @private
     */
    private _aggiungiLavorazioneHandle(){
        const context = this.context as StorageManagerStatus;

        if(
            this.props.aggiungiLavorazione &&
            this.props.aggiungiLavorazione(
                this.props.immagini[0].name,
                this.state.lavorazioneSelezionata,
                !!this.state.sezioneSecondaria,
                context.GetStorageData(`Lavorazione-${this.props.immagini[0].name}`).map(storageData => storageData.name)
            )
        ){
            if(!this._sezioneSecondariaPossiedeDati)
                this.setState({lavorazioneSelezionata: ""});
        }
    }

    private _inizializzaVisualizzazioneLavorazione(sezioniConfigurabili: SezioneConfigurabile[]){
        const sezioniImmagini = sezioniConfigurabili.filter(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.immagineAmmessa === ImmagineAmmessaEnum.IMMAGINE);
        this._sezionePrincipale = sezioniImmagini
            .find(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.sezioneImmaginePrincipale);
        const sezioneSecondaria = sezioniImmagini
            .find(sezioneConfigurabile => !sezioneConfigurabile.configurazioneSezione.sezioneImmaginePrincipale);

        this.setState({ sezioneSecondaria });
        const context = this.context as StorageManagerStatus;
        if(context){
            const _sezioneSecondariaData = context.GetStorageData(`Lavorazione-${this.props.immagini[0].name}`)[0];
            if(_sezioneSecondariaData){
                this._sezioneSecondariaPossiedeDati = true;
                sezioneSecondaria.impostaImmagineSezione(_sezioneSecondariaData);
            }
        }

        if(this._sezionePrincipale)
            this._sezionePrincipale.impostaImmagineSezione(this.props.immagini[0]);
    }

    public render() {
        return (
            <SectionLayout
                size={"largeRelative"}
                addPadding={true}
                showBorder={true}>
                <ResponsiveLabel content={"Selezione corrente"} type={"medium"} alignment={"center"}/>
                <MultiSelettore
                    immagini={this.props.immagini}
                    posizioniSelezionate={[]}
                    onChange={selezione => this.setState({nomeImmagineOverlay: selezione[0]})}/>
                <IfContainer condition={this.props.modificheCartaExtra}>
                    <SelectLine
                        label={"Seleziona carta extra"}
                        placeHolder={"Seleziona"}
                        elements={CartaAlternativa.filter(carta => !carta.cartoncinoFlag).map(carta => ({label: carta.nomeCarta}))}
                        value={this.state.cartaExtraSelezionata}
                        onChange={v => this.setState({cartaExtraSelezionata: v, lavorazioneSelezionata: "", sezioneSecondaria: undefined})}/>
                </IfContainer>
                {this.props.modificheLavorazioni && this.props.immagini.length === 1 && <Fragment>
                    <SelectLine
                        label={"Seleziona lavorazione"}
                        placeHolder={"Seleziona"}
                        elements={Lavorazioni.map(v => ({label: v}))}
                        value={this.state.lavorazioneSelezionata}
                        onChange={v => {
                            this._sezioneSecondariaPossiedeDati = false;
                            this.setState({
                                lavorazioneSelezionata: v,
                                sezioneSecondaria: undefined,
                                cartaExtraSelezionata: ""
                            });
                        }}/>
                    <IfContainer condition={this._modelloEsiste()}>
                        <ManagerRenderizzatoreModelli
                            nomeManager={`Lavorazione-${this.props.immagini[0].name}`}
                            aspectRatioCanvas={"2 / 1"}
                            nomeModelloSelezionato={this.state.lavorazioneSelezionata}
                            intercettaEventiClick={false}
                            onModelloInizializzato={(modelloConfigurabile, sezioniConfigurabili) => {
                                this._inizializzaVisualizzazioneLavorazione(sezioniConfigurabili);
                            }}
                        />
                        {
                            this.state.sezioneSecondaria &&
                            <StorageManagerContext.Consumer>{
                                storage => (
                                    <TextLine
                                        label={this.state.sezioneSecondaria.configurazioneSezione.nomeVisualizzato}
                                        type={'file'}
                                        value={storage.GetStorageData(`Lavorazione-${this.props.immagini[0].name}`)[0]?.name || ''}
                                        onFileUpload={(fileName, fileType, file) => {
                                            if(imageTypeSupported(fileType)){
                                                storage.DeleteStorageData(`Lavorazione-${this.props.immagini[0].name}`);
                                                storage.SetStorageData(`Lavorazione-${this.props.immagini[0].name}`, fileName, file).then(storageData => {
                                                    this.state.sezioneSecondaria && this.state.sezioneSecondaria.impostaImmagineSezione(storageData);
                                                    this._sezioneSecondariaPossiedeDati = true;
                                                    this._aggiungiLavorazioneHandle();
                                                });
                                            }
                                        }}/>
                                )
                            }</StorageManagerContext.Consumer>
                        }
                    </IfContainer>
                </Fragment>}
                <IfContainer
                    condition={
                        !!this.state.cartaExtraSelezionata ||
                        !!this.state.lavorazioneSelezionata
                    }
                    elseComponent={
                        <Button
                            content={this.props.immagini.length > 1 ? "Elimina immagini" : "Elimina immagine"}
                            type={"medium"}
                            buttonType={"full-normal-negative"}
                            onClick={() => this._rimuoviFogliHandler()}/>
                    }>
                    <Responsive2Col>
                        <Button
                            content={!!this.state.cartaExtraSelezionata || this.props.immagini.length > 1 ? "Aggiungi carta extra" : (!!this.state.sezioneSecondaria ? "Lavorazione aggiunta all'upload dell'immagine" : "Aggiungi lavorazione")}
                            type={"medium"}
                            disabled={!!this.state.sezioneSecondaria}
                            buttonType={"full-normal"}
                            onClick={() => !!this.state.cartaExtraSelezionata || this.props.immagini.length > 1 ? this._aggiungiCartaExtraHandle() : this._aggiungiLavorazioneHandle()}/>
                        <Button
                            content={this.props.immagini.length > 1 ? "Elimina immagini" : "Elimina immagine"}
                            type={"medium"}
                            buttonType={"full-normal-negative"}
                            onClick={() => this._rimuoviFogliHandler()}/>
                    </Responsive2Col>
                </IfContainer>
                <IfContainer condition={!!this.state.nomeImmagineOverlay}>
                    <ImagePreviewOverlay
                        nomeImmagine={this.state.nomeImmagineOverlay}
                        sorgenteImmagine={this.props.immagini.find(immagine => immagine.name === this.state.nomeImmagineOverlay)?.url()}
                        onCloseClick={() => this.setState({nomeImmagineOverlay: ""})}/>
                </IfContainer>
            </SectionLayout>
        );
    }
}

DADMIndexAzione.contextType = StorageManagerContext;
