//QG 30/06/2024 MD

import {ConfigurazioneModelloInterface, ConfigurazioneSezioneInterface} from "tici_commons";
import {ModelloConfigurabile} from "../GestioneModelli/ModelloConfigurabile";

export default class MangerRenderizzatoreModelliHelper{
    public static calcolaAspectRatio(
        configurazioneSezione: ConfigurazioneSezioneInterface,
        configurazioneModello: ConfigurazioneModelloInterface,
        modelloConfigurabile: ModelloConfigurabile,
        modalitaVisualizzazioneAlternativa?: boolean
    ){
        let esito = "1 / 1";

        if(configurazioneSezione){
            let z = Math.abs(configurazioneSezione.dimensioneSezioneSuperficie.z); //Inteso come X
            let x = Math.abs(configurazioneSezione.dimensioneSezioneSuperficie.x); //Inteso come Y

            if(configurazioneModello.modelloQuadratoFlag){
                z *= modelloConfigurabile.scalaAsseZ;
            }

            if(modalitaVisualizzazioneAlternativa){
                const comodo = z;
                z = x;
                x = comodo;
            }

            esito = `${Math.ceil(z)} / ${Math.ceil(x)}`
        }

        return esito;
    }

    public static calcolaFattoreMoltiplicativoX(
        configurazioneSezione: ConfigurazioneSezioneInterface,
        configurazioneModello: ConfigurazioneModelloInterface,
        modelloConfigurabile: ModelloConfigurabile,
        modalitaVisualizzazioneAlternativa?: boolean
    ): number{
        let esito = 1;

        const aspectRatio = MangerRenderizzatoreModelliHelper.calcolaAspectRatio(configurazioneSezione, configurazioneModello, modelloConfigurabile, modalitaVisualizzazioneAlternativa);
        const parti = aspectRatio.split('/').map(el => parseFloat(el.trim()));
        if(parti.length === 2){
            esito = parti[0] / parti[1];
        }

        return esito;
    }
}
