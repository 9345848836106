import React, {Fragment} from "react";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import SezioneCustodia from "./CustodiaSezioni/SezioneCustodia";
import SezioneCofanettoPiccolo from "./CustodiaSezioni/SezioneCofanettoPiccolo";
import {StorageManagerStatus} from "../../Storage/StorageManager";
import {
    GenerateGroup,
    GenerateRiepilogoBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../SezioneGenerica";
import {FotolibroModel, TipoFotolibroType} from "tici_commons";
import SezioneInformazioniAggiuntiveCustodia from "./CustodiaSezioni/SezioneInformazioniAggiuntiveCustodia";

export interface CustodiaProps{
    tipoFotolibro: TipoFotolibroType,
    formatoAlbumPrincipale: string,
    custodiaFlag: boolean,
    albumRidottoFlag: boolean,
    albumRidottoQuantita: number
}

export default class Custodia extends SezioneGenerica<"custodia", CustodiaProps, any>{
    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return GenerateRiepilogoBox(
            'Custodia',
            ...GenerateGroup(
                fotolibro.informazioniBase.custodiaFlag,
                new SezioneCustodia(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore),
                new SezioneInformazioniAggiuntiveCustodia(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore),
                new SezioneCofanettoPiccolo(undefined, undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore)
            )
        );
    }

    render() {
        return (
            <IfContainer
                condition={this.props.custodiaFlag}
                elseComponent={
                    <ResponsiveLabel content={"La custodia non è presente nel progetto"} type={"large"} alignment={"center"}/>
                }>
                <Fragment>
                    <SezioneCustodia
                        formatoAlbumPrincipale={this.props.formatoAlbumPrincipale}
                        storage={this.props.storage}
                        SetParentState={this.props.SetParentState}
                        data={this.props.data}
                        tipoFotolibro={this.props.tipoFotolibro}/>
                    <SezioneInformazioniAggiuntiveCustodia
                        SetParentState={this.props.SetParentState}
                        data={this.props.data}
                        storage={this.props.storage}/>
                    <IfContainer condition={this.props.albumRidottoFlag && this.props.albumRidottoQuantita > 0}>
                        <SezioneCofanettoPiccolo
                            storage={this.props.storage}
                            SetParentState={this.props.SetParentState}
                            data={this.props.data}
                            modalitaConfiguratore={false}/>
                    </IfContainer>
                </Fragment>
            </IfContainer>
        );
    }
}
