import downArrowIcon from "../Media/Images/Icons/downArrowIcon.png";
import plusIcon from "../Media/Images/Icons/plusIcon.png";
import penIcon from "../Media/Images/Icons/penIcon.png";
import listIcon from "../Media/Images/Icons/listIcon.png";
import databaseIcon from "../Media/Images/Icons/databaseIcon.png";
import xIcon from "../Media/Images/Icons/xIcon.png";
import downloadIcon from "../Media/Images/Icons/downloadIcon.png";
import downloadSectionIcon from "../Media/Images/Icons/downloadSectionIcon.png";
import rilegaturaIcon from "../Media/Images/Icons/CategorySwitcher/rilegaturaIcon.png";
import copertinaIcon from "../Media/Images/Icons/CategorySwitcher/copertinaIcon.png";
import cofanettoIcon from "../Media/Images/Icons/CategorySwitcher/cofanettoIcon.png";
import accessoriIcon from "../Media/Images/Icons/CategorySwitcher/accessoriIcon.png";
import riepilogoIcon from "../Media/Images/Icons/CategorySwitcher/riepilogoIcon.png";
import coordinatiIcon from "../Media/Images/Icons/CategorySwitcher/coordinatiIcon.png";
import informazioniBaseIcon from "../Media/Images/Icons/CategorySwitcher/informazioniBaseIcon.png";
import userIcon from "../Media/Images/Icons/userIcon.png";
import undoIcon from "../Media/Images/Icons/undoIcon.png";
import searchIcon from "../Media/Images/Icons/searchIcon.png";
import bellIcon from "../Media/Images/Icons/bellIcon.png";
import checkIcon from "../Media/Images/Icons/checkIcon.png";
import detailsIcon from "../Media/Images/Icons/detailsIcon.png";
import azureEyeIcon from "../Media/Images/Icons/azureEyeIcon.png";
import eyeIcon from "../Media/Images/Icons/eyeIcon.png";
import alertIcon from "../Media/Images/Icons/yellowAlertIcon.png";
import approveIcon from "../Media/Images/Icons/approveIcon.png";
import disapproveIcon from "../Media/Images/Icons/disapproveIcon.png";
import trashIcon from "../Media/Images/Icons/trashIcon.png";
import personificationIcon from "../Media/Images/Icons/personificationIcon.png";
import buisnessmanIcon from "../Media/Images/Icons/buisnessmanIcon.png";
import cameraIcon from "../Media/Images/Icons/cameraIcon.png";
import gearIcon from "../Media/Images/Icons/gearIcon.png";
import crownIcon from "../Media/Images/Icons/crownIcon.png";
import rollIcon from "../Media/Images/Icons/SuperUser/rollIcon.png";
import wrenchIcon from "../Media/Images/Icons/SuperUser/wrenchIcon.png";
import _3dIcon from "../Media/Images/Icons/SuperUser/3dIcon.png";
import timeIcon from "../Media/Images/Icons/timeIcon.png";
import whatsappIcon from "../Media/Images/Icons/whatsappIcon.png";
import analyticsIcon from "../Media/Images/Icons/analyticsIcon.png";
import conferenceIcon from "../Media/Images/Icons/conferenceIcon.png";
import messageIcon from "../Media/Images/Icons/messageIcon.png";
import homeIcon from "../Media/Images/Icons/homeIcon.png";
import ordineIcon from "../Media/Images/Icons/ordineIcon.png";
import keyIcon from "../Media/Images/Icons/keyIcon.png";
import squareIcon from "../Media/Images/Icons/squareIcon.png";
import zoomInIcon from "../Media/Images/Icons/zoomInIcon.png";
import zoomOutIcon from "../Media/Images/Icons/zoomOutIcon.png";
import simpleArrowIcon from "../Media/Images/Icons/simpleArrowIcon.png";
import rotateRightIcon from "../Media/Images/Icons/rotateRightIcon.png";
import rotateLeftIcon from "../Media/Images/Icons/rotateLeftIcon.png";
import centerIcon from "../Media/Images/Icons/centerIcon.png";
import uvIcon from "../Media/Images/Icons/uvIcon.png";
import pdfIcon from "../Media/Images/Icons/pdfIcon.png";

export default class ImageService{
    private static _icons = {
        downArrowIcon,
        plusIcon,
        penIcon,
        listIcon,
        databaseIcon,
        xIcon,
        downloadIcon,
        downloadSectionIcon,
        rilegaturaIcon,
        copertinaIcon,
        cofanettoIcon,
        accessoriIcon,
        riepilogoIcon,
        coordinatiIcon,
        informazioniBaseIcon,
        userIcon,
        undoIcon,
        searchIcon,
        bellIcon,
        checkIcon,
        detailsIcon,
        azureEyeIcon,
        eyeIcon,
        alertIcon,
        approveIcon,
        disapproveIcon,
        trashIcon,
        personificationIcon,
        buisnessmanIcon,
        cameraIcon,
        gearIcon,
        crownIcon,
        rollIcon,
        wrenchIcon,
        _3dIcon,
        timeIcon,
        whatsappIcon,
        analyticsIcon,
        conferenceIcon,
        messageIcon,
        homeIcon,
        ordineIcon,
        keyIcon,
        squareIcon,
        zoomInIcon,
        zoomOutIcon,
        simpleArrowIcon,
        rotateRightIcon,
        rotateLeftIcon,
        centerIcon,
        uvIcon,
        pdfIcon
    }

    public static getImage(imageName: keyof typeof ImageService._icons): string{
        return ImageService._icons[imageName]?? "";
    }
}
