import {FotolibroModel} from "tici_commons";
import {StorageManagerStatus} from "../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import OrganizzatoreImmaginiService from "./OrganizzatoreImmaginiService";
import {fromMsToTime} from "../Utils/CommonFunctions";
import TopLevelComponentStorage from "../Core/Arch/TopLevelComponentStorage";
import OrdineService from "./OrdineService";
import LocalStorageService from "./LocalStorageService";
import RedirectService from "./RedirectService";
import {
    BackgroundOrdineManagerContextInterface
} from "../Primary/BackgroundOrdineManager/BackgroundOrdineManager";

export default class RiepilogoServiceNWS{
    public static async InviaOrdine(backgroundContext: BackgroundOrdineManagerContextInterface, fotoLibro: FotolibroModel, storage: StorageManagerStatus, indiceBase64?: string, datBase64?: string) {
        let errorStatus = false;
        let errorMessage = "";

        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Invio dell'ordine", "Creazione dell'associazione dei riferimenti sul database");
        const ordineId = await OrdineService.InviaOrdine(fotoLibro.informazioniBase.nomeProgetto);

        if (ordineId !== false) {
            TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Invio dell'ordine", "Invio dei dati del progetto");
            if (!errorStatus)
                errorStatus = !await OrdineService.UploadOrdineData(ordineId, "InformazioniBase", fotoLibro.informazioniBase, {miniatura: !!indiceBase64});
            if (fotoLibro.informazioniBase.tipoFotolibro !== 'accessori') {
                if (!errorStatus)
                    errorStatus = !await OrdineService.UploadOrdineData(ordineId, "Rilegatura", fotoLibro.rilegatura);
                if (!errorStatus)
                    errorStatus = !await OrdineService.UploadOrdineData(ordineId, "Copertina", fotoLibro.copertina, {miniatura: !!LocalStorageService.CopertinaImmagineGenerata});
            }
            if (!errorStatus)
                errorStatus = !await OrdineService.UploadOrdineData(ordineId, "Custodia", fotoLibro.custodia, {miniatura: !!LocalStorageService.CustodiaImmagineGenerata && fotoLibro.informazioniBase.custodiaFlag});
            if (!errorStatus)
                errorStatus = !await OrdineService.UploadOrdineData(ordineId, "Accessori", fotoLibro.accessori);

            if (!errorStatus) {
                TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Caricamento miniature", "Stiamo caricando le miniature");
                if (!!LocalStorageService.CopertinaImmagineGenerata)
                    await OrdineService.UploadOrdineMiniatura(ordineId, "copertina", LocalStorageService.CopertinaImmagineGenerata);
                if (!!LocalStorageService.CustodiaImmagineGenerata && fotoLibro.informazioniBase.custodiaFlag)
                    await OrdineService.UploadOrdineMiniatura(ordineId, "custodia", LocalStorageService.CustodiaImmagineGenerata);
                LocalStorageService.CopertinaImmagineGenerata = undefined;
                LocalStorageService.CustodiaImmagineGenerata = undefined;
                if(indiceBase64)
                    await OrdineService.UploadOrdineMiniatura(ordineId, "indice", indiceBase64);
            }

            if (!errorStatus) {
                TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();  //Nascondiamo la loading window
                const backgroundOrderIndex = await backgroundContext.GeneraOrdineMenu();
                RedirectService.GoToHomepage();

                let mediaTime = 0;                  //Tempo medio di upload per il singolo file

                const immaginiDaInviare = OrganizzatoreImmaginiService.OrganizzaImmagini(storage, fotoLibro);
                if (immaginiDaInviare.length > 0) {
                    backgroundContext.ScriviOrdineMenu(
                        backgroundOrderIndex,
                        fotoLibro.informazioniBase.nomeProgetto,
                        "Stiamo caricando i file del tuo lavoro"
                    );
                    for(let i = 0; i < immaginiDaInviare.length; i++){
                        const immagine = immaginiDaInviare[i];
                        if(!errorStatus){
                            const startTime = Date.now();
                            const esito = await OrdineService.UploadOrdineFile(
                                ordineId,
                                immagine[1].name,
                                await immagine[1].base64(),
                                immagine[0],
                                (status) => {
                                    backgroundContext.ScriviOrdineMenu(
                                        backgroundOrderIndex,
                                        `${fotoLibro.informazioniBase.nomeProgetto} ${Math.round((i / immaginiDaInviare.length) * 100)}%`,
                                        `Stiamo caricando ${immagine[1].name} ${Math.round(status * 100)}%`,
                                        `Stimiamo ${fromMsToTime((immaginiDaInviare.length - i) * mediaTime)} al completamento`
                                    );
                                }
                            )
                            if(esito){
                                const now = Date.now() - startTime;
                                mediaTime = (mediaTime + now) / 2;
                            }else{
                                errorStatus = true;
                                errorMessage = "Non é stato possibile inviare tutti i file sul server";
                            }
                        }
                    }
                }

                if(!errorStatus && datBase64){
                    const esito = await OrdineService.UploadOrdineFile(
                        ordineId,
                        'progetto.dat',
                        datBase64,
                        'Dat',
                        (status) => {
                            backgroundContext.ScriviOrdineMenu(
                                backgroundOrderIndex,
                                fotoLibro.informazioniBase.nomeProgetto,
                                `Caricamento file DAT ${Math.round(status * 100)}%`
                            );
                        }
                    );

                    if(!esito){
                        errorStatus = true;
                        errorMessage = "Errore durante il caricamento del file DAT";
                    }
                }

                backgroundContext.CancellaOrdineMenu(backgroundOrderIndex);
            }else {
                errorMessage = "Errore durante l'invio delle informazioni dell'ordine";
            }
        } else {
            errorStatus = true;
            errorMessage = "Non é stato possibile creare l'associazione sul database per l'ordine";
        }

        if(!errorStatus){
            const backgroundCompressioneIndex = await backgroundContext.GeneraOrdineMenu();
            backgroundContext.ScriviOrdineMenu(
                backgroundCompressioneIndex,
                fotoLibro.informazioniBase.nomeProgetto,
                "Stiamo comprimendo le informazioni sul server"
            );
            const immaginiDaInviare = OrganizzatoreImmaginiService.OrganizzaImmagini(storage, fotoLibro);
            if(immaginiDaInviare.length > 0 ? await OrdineService.ComprimiDati(ordineId as number) : true){
                if(!await OrdineService.ValidaOrdine(ordineId as number)){
                    errorStatus = true;
                    errorMessage = "Non è stato possibile validare lo stato dell'ordine creato";
                }
            }else{
                errorStatus = true;
                errorMessage = "Non é stato possibile comprimere i dati dell'ordine"
            }
            backgroundContext.CancellaOrdineMenu(backgroundCompressioneIndex);
        }

        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        if(!errorStatus){
            TopLevelComponentStorage.GetTopLevel('confirmWindow')
                .showConfirmWindow(
                    "Invio dell'ordine riuscito",
                    `L'ordine "${fotoLibro.informazioniBase.nomeProgetto}" è stato inviato correttamente. Vuoi andare agli ordini?`,
                    "DoubleButton",
                    () => {
                        TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                        RedirectService.GoToOrdini();
                    }
                )
        }else{
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow("Invio dell'ordine fallito", errorMessage);
        }
    }
}
