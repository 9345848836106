//QG 30/06/2024 MD

import {ElementoConfigurabile, RenderObjectUserData} from "./ElementoConfigurabile";
import {
    ConfigurazioneModelloInterface,
    ConfigurazioneSezioneInterface,
    ImmagineAmmessaEnum,
    NomeSezioneInformazioneType,
    SezioneGenericaModel
} from "tici_commons";
import {
    BufferGeometry,
    CanvasTexture,
    Color,
    DoubleSide,
    Float32BufferAttribute,
    Mesh,
    MeshBasicMaterial,
    MeshLambertMaterial,
    Object3D,
    PlaneGeometry,
    Shape,
    ShapeGeometry,
    TextureLoader,
    Vector2,
    Vector3
} from "three";
import Materiali from "../../DatabaseData/Materiali";
import MangerRenderizzatoreModelliHelper from "../ManagerRenderizzatoreModelli/MangerRenderizzatoreModelliHelper";
import {ModelloConfigurabile} from "./ModelloConfigurabile";
import {toRadians} from "./Utils";
import {StorageData} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";

export default abstract class ElementoRenderizzabile extends ElementoConfigurabile{
    private _datiSezioneCollegati = false;
    private _materialeInput: MeshBasicMaterial;

    private _configurazioneSezione: ConfigurazioneSezioneInterface;
    private _configurazioneModello: ConfigurazioneModelloInterface;
    private _modelloConfigurabileContenitore: ModelloConfigurabile;

    private _sezioneSuperficie: Mesh;
    private _visualizzaSezioneSuperficieInModifica = false;
    private _elementoSelezionatoPerModifica = false;

    constructor(
        objectName: string,
        renderObject: Object3D,
        configurazioneSezione: ConfigurazioneSezioneInterface,
        configurazioneModello: ConfigurazioneModelloInterface,
        modelloConfigurabileContenitore: ModelloConfigurabile
    ) {
        super(objectName, renderObject);
        this._configurazioneSezione = configurazioneSezione;
        this._configurazioneModello = configurazioneModello;
        this._modelloConfigurabileContenitore = modelloConfigurabileContenitore;
        this._setupSezioneSuperficie();
    }

    private _setupSezioneSuperficie(){
        const geometry = new BufferGeometry();
        this._sezioneSuperficie = new Mesh(
            geometry,
            new MeshBasicMaterial({side: DoubleSide, transparent: true})
        );
        this._sezioneSuperficie.name = "SezioneSuperficie";
        (this._sezioneSuperficie.userData as RenderObjectUserData).escludiBounding = true;
        this._sezioneSuperficie.rotateX(toRadians(-90));
        this.renderObject.add(this._sezioneSuperficie);
    }

    private _aggiornaSezioneSuperficie(){
        const condizioneVisibilitaGenerale =
            [ImmagineAmmessaEnum.TELA, ImmagineAmmessaEnum.PLEX, ImmagineAmmessaEnum.TELA_PLEX].includes(this._configurazioneSezione.immagineAmmessa) ||
            this._configurazioneSezione.sezioneAmmetteUvFlag;

        const condizioneVisibilitaSecondaria =
            this._datiSezioneCollegati || (this._elementoSelezionatoPerModifica && this._visualizzaSezioneSuperficieInModifica);

        this._sezioneSuperficie.visible =
            condizioneVisibilitaGenerale &&
            condizioneVisibilitaSecondaria &&
            !this._configurazioneSezione.nomeSezioneRiferimentoInformazioni;

        this.materialeSezioneSuperfice.opacity = this._elementoSelezionatoPerModifica ? 0.6 : 1;
        if(!this._datiSezioneCollegati)
            this.materialeSezioneSuperfice.color = new Color(0x00ff00);
        else this.materialeSezioneSuperfice.color = new Color(0xffffff);

        if(this._configurazioneSezione.sezioneSuperficiePoligonaleFlag){
            const puntiPoligonali = this._configurazioneSezione.puntiPoligonaliSezioneSuperficie.map(punto => ({x: -punto.x, y: -punto.y, z: -punto.z}));
            const shape = new Shape();
            const widthMezzi = this._configurazioneSezione.dimensioneSezioneSuperficie.z * 0.5;
            const heightMezzi = this._configurazioneSezione.dimensioneSezioneSuperficie.x * 0.5;
            for(let i = 0; i < this._configurazioneSezione.puntiPoligonaliSezioneSuperficie.length; i++){
                const target = puntiPoligonali[i];
                if(i === 0){
                    shape.moveTo(target.x * widthMezzi, target.z * heightMezzi);
                }else{
                    shape.lineTo(target.x * widthMezzi, target.z * heightMezzi);
                }
            }
            shape.moveTo(puntiPoligonali[0].x * widthMezzi, puntiPoligonali[0].z * heightMezzi);
            shape.closePath();

            const geometry = new ShapeGeometry(shape);
            this._sezioneSuperficie.geometry = geometry;
            geometry.computeBoundingBox();

            const max = geometry.boundingBox.max;
            const min = geometry.boundingBox.min;

            const offset = new Vector2(0 - min.x, 0 - min.y);
            const range = new Vector2(max.x - min.x, max.y - min.y);

            const uvs = [];

            const positionAttribute = geometry.attributes.position;
            for (let i = 0; i < positionAttribute.count; i++) {
                const x = positionAttribute.getX(i);
                const y = positionAttribute.getY(i);

                let u = (x + offset.x) / range.x;
                if(this._configurazioneSezione.specchiaAsseYSezioneSuperficie)
                    u = 1 - u;
                let v = (y + offset.y) / range.y;
                if(this._configurazioneSezione.specchiaAsseXSezioneSuperficie)
                    v = 1 - v;

                uvs.push(v, u);
            }

            geometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
        }else{
            this._sezioneSuperficie.geometry = new PlaneGeometry(
                this._configurazioneSezione.dimensioneSezioneSuperficie.x,
                this._configurazioneSezione.dimensioneSezioneSuperficie.z
            );
        }

        this._sezioneSuperficie.position.set(
            this._configurazioneSezione.offsetSezioneSuperficie.x,
            this._configurazioneSezione.offsetSezioneSuperficie.y,
            this._configurazioneSezione.offsetSezioneSuperficie.z,
        );

        this._sezioneSuperficie.rotation.set(
            toRadians((this._configurazioneSezione.sezioneSuperficiePoligonaleFlag ? 90 : -90) + this._configurazioneSezione.rotazioneSezioneSuperficie.x),
            toRadians(this._configurazioneSezione.rotazioneSezioneSuperficie.y),
            toRadians(this._configurazioneSezione.rotazioneSezioneSuperficie.z),
        )

        this.materialeSezioneSuperfice.needsUpdate = true;
    }

    public async aggiornaVisualizzazioneModello(datiSezione: NomeSezioneInformazioneType[], storage: StorageData[]){
        const datoSezione = this._recuperaDatoSezione(datiSezione);
        if(datoSezione){
            this._datiSezioneCollegati = true;
            const canvas = this._generaCanvasSezioneSuperficie();

            this.aggiornaInformazioneBase(datoSezione);
            this._aggiornaInformazioniRotazioneCanvas(canvas)
            await this._aggiornaInformazioneImmagine(canvas, datoSezione, storage);
            await this.aggiornaInformaziniUv(canvas, datoSezione, storage);

            this.materialeSezioneSuperfice.map = new CanvasTexture(canvas);
            this.materialeSezioneSuperfice.color = new Color(0xffffff);
            this._aggiornaSezioneSuperficie();
        }
    }

    public async impostaImmagineSezione(storageData: StorageData){
        if(storageData && this.configurazioneSezione.immagineAmmessa === ImmagineAmmessaEnum.IMMAGINE){
            new TextureLoader().load(storageData.url(), texture => {
                this._datiSezioneCollegati = true;
                this.materialeRenderObject.color = new Color(0xffffff);
                this.materialeRenderObject.map = texture;
                this.materialeRenderObject.needsUpdate = true;
            });
        }
    }

    public update() {
        (this.renderObject.userData as RenderObjectUserData).escludiEventoClick = false;

        if(this.renderObject)
            this.renderObject.scale.z = this.scalaAsseZ;

        if(this._configurazioneSezione.sezioneRuotaAllaRotazioneModelloAlternativa){
            if(this.modalitaVisualizzazioneAlternativa){
                this.centerRotationPivot.rotationVector.set(0, 90, 0);
                (this.renderObject as Mesh).geometry.computeBoundingBox();
                const size = (this.renderObject as Mesh).geometry.boundingBox.getSize(new Vector3());

                //ragionamento:
                /*
                Prima di tutto calcolo quanto é più grande z rispetto a x e viceversa per x.
                Ottenendo questi valori inverto con le scale le dimensioni di x e z.
                Dal momento che adesso x é il nuovo z gli applico la scala della asseZ calcolata.
                That's it
                 */
                const scalaAsseX = (size.z / size.x);
                const scalaAsseZ = (size.x / size.z);

                this.renderObject.scale.z = scalaAsseZ;
                this.renderObject.scale.x = scalaAsseX * this.scalaAsseZ;

            }else{
                this.renderObject.scale.x = 1;
                this.centerRotationPivot.rotationVector.set(0, 0, 0);
            }
        }

        if(this.materialeRenderObject) {
            if(!this._datiSezioneCollegati)
                this.materialeRenderObject.color = new Color(parseInt(this._configurazioneSezione.coloreSezione.replaceAll('#', '0x')));

            if(this._configurazioneSezione.sezioneColoreFlag)
                (this.renderObject.userData as RenderObjectUserData).escludiEventoClick = true;

            if(this._configurazioneSezione.sezioneContenitriceFlag) {
                this.materialeRenderObject.color = new Color(0xffffff);
                (this.renderObject.userData as RenderObjectUserData).escludiEventoClick = true;
            }

            if(this._configurazioneSezione.livelloTrasparenza > 0){
                this.materialeRenderObject.transparent = true;
                this.materialeRenderObject.opacity = 1 - (this._configurazioneSezione.livelloTrasparenza / 100);
                if(!this._configurazioneSezione.sezioneAmmetteUvFlag && this._configurazioneSezione.immagineAmmessa === ImmagineAmmessaEnum.NO_IMMAGINI)
                    (this.renderObject.userData as RenderObjectUserData).escludiEventoClick = true;
            }else {
                this.materialeRenderObject.transparent = false;
                this.materialeRenderObject.opacity = 1;
            }

            if(this._configurazioneSezione.singolaImmagineName.length > 0){
                if(!this._configurazioneSezione.sezioneAmmetteUvFlag)
                    (this.renderObject.userData as RenderObjectUserData).escludiEventoClick = true;
                new TextureLoader().load(
                    this._configurazioneSezione.singolaImmagineBase64,
                    texture => {
                        this.materialeRenderObject.map = texture;
                        this.materialeRenderObject.needsUpdate = true;
                    }
                );
            }else if(!this._datiSezioneCollegati) {
                this.materialeRenderObject.map = undefined;
            }

            this.materialeRenderObject.needsUpdate = true;
        }

        this.visible = !this._configurazioneSezione.sezioneNonVisibileFlag;
        if(!this.visible)
            (this.renderObject.userData as RenderObjectUserData).escludiEventoClick = true;

        this._materialeInput = undefined;   //TODO Il materiale di input é inserito e collegato, valutarne un eventuale utilizzo
        this._aggiornaSezioneSuperficie();
        super.update();
    }

    //region Aggiornatori

    private _aggiornaInformazioniRotazioneCanvas(canvas: HTMLCanvasElement){
        const ctx = canvas.getContext('2d');
        ctx.translate(canvas.width * 0.5, canvas.height * 0.5);
        ctx.rotate(toRadians(this.modalitaVisualizzazioneAlternativa && !this._configurazioneSezione.sezioneRuotaAllaRotazioneModelloAlternativa ? 0 : 90));
        ctx.translate(canvas.width * -0.5, canvas.height * -0.5);
    }

    public aggiornaInformazioneBase(datoSezione: SezioneGenericaModel){
        if(this.materialeRenderObject && datoSezione.informazione){
            switch (datoSezione.tipoSezione){
                case "sezioneMateriale":
                    this.materialeRenderObject.color = new Color(0x000000);
                    const materialPath = Materiali.GetMaterialUrl(datoSezione.informazione);
                    new TextureLoader().load(materialPath, texture => {
                        this.materialeRenderObject.color = new Color(0xffffff);
                        this.materialeRenderObject.map = texture;
                        this.materialeRenderObject.needsUpdate = true;
                    });
                    break;
                case "sezioneLegno":
                    this.materialeRenderObject.map = undefined;
                    this.materialeRenderObject.color = new Color(this._mappaColoreLegno(datoSezione.informazione));
                    this.materialeRenderObject.needsUpdate = true;
                    break;
                case "sezionePlex":
                    this.materialeRenderObject.map = undefined;
                    this.materialeRenderObject.color = new Color(this._mappaColorePlex(datoSezione.informazione));
                    this.materialeRenderObject.needsUpdate = true;
                    break;
                case "sezioneColore":
                    this.materialeRenderObject.map = undefined;
                    this.materialeRenderObject.color = new Color(this._mappaColoreColore(datoSezione.informazione));
                    this.materialeRenderObject.needsUpdate = true;
                    break;
            }
        }
    }

    private _aggiornaInformazioneImmagine(canvas: HTMLCanvasElement, datoSezione: SezioneGenericaModel, storage: StorageData[]): Promise<void>{
        return new Promise(resolve => {
            const ctx = canvas.getContext('2d');
            const immagine = storage.find(storageData => storageData.category.includes(this._configurazioneSezione.nomeReale) && storageData.category.includes('immagine'));

            if(datoSezione.informazioneImmagine && immagine){
                const image = document.createElement('img');
                image.addEventListener('load', () => {
                    const partiRatio = this._recuperaPartiRatio();
                    const aspectImage = image.height / image.width;

                    const posX = (datoSezione.informazioneImmagine.posizioneXImmagine / 100) * canvas.width;
                    const posY = (datoSezione.informazioneImmagine.posizioneYImmagine / 100)  * canvas.height;
                    const width = canvas.width * datoSezione.informazioneImmagine.scalaImmagine;
                    const height = canvas.width * datoSezione.informazioneImmagine.scalaImmagine * aspectImage * (partiRatio[0] / partiRatio[1]);

                    ctx.drawImage(image, posX, posY, width, height);
                    resolve();
                });
                image.addEventListener('error', () => resolve());
                image.src = immagine.url();
            }else resolve();
        });
    }

    protected aggiornaInformaziniUv(canvas: HTMLCanvasElement, datoSezione: SezioneGenericaModel, storage: StorageData[]): Promise<void>{
        return new Promise(resolve => {
            const ctx = canvas.getContext('2d');
            const immagine =
                storage.find(storageData => storageData.category.includes(this._configurazioneSezione.nomeReale) && storageData.category.includes('uv'));
            if(datoSezione.informazioneUv && immagine){
                const image = document.createElement('img');
                image.addEventListener('load', () => {
                    const partiRatio = this._recuperaPartiRatio();
                    const aspectImage = image.height / image.width;

                    const posX = (datoSezione.informazioneUv.posizioneXUv / 100) * canvas.width;
                    const posY = (datoSezione.informazioneUv.posizioneYUv / 100)  * canvas.height;
                    const width = canvas.width * datoSezione.informazioneUv.scalaUv;
                    const height = canvas.width * datoSezione.informazioneUv.scalaUv * aspectImage * (partiRatio[0] / partiRatio[1]);

                    ctx.translate(posX + width * 0.5, posY + height * 0.5);
                    ctx.rotate(toRadians(datoSezione.informazioneUv.rotazioneZUv))
                    ctx.translate(-(posX + width * 0.5), -(posY + height * 0.5));
                    ctx.drawImage(image, posX, posY, width, height);
                    resolve();
                });
                image.addEventListener('error', () => resolve());
                image.src = immagine.url();
            }else resolve();
        });
    }

    //endregion

    //region Supporto

    private _generaCanvasSezioneSuperficie(): HTMLCanvasElement{
        const canvas = document.createElement('canvas');
        canvas.width = 1024;
        canvas.height = 1024;
        return canvas;
    }

    private _recuperaDatoSezione(datiSezione: NomeSezioneInformazioneType[]): SezioneGenericaModel{
        let esito = undefined;

        const configurazione = this._configurazioneSezione;
        if(configurazione){
            const datoSezione = datiSezione.find(datoSezione => datoSezione[0] === configurazione.nomeReale);
            if(datoSezione){
                esito = datoSezione[1];
            }
        }

        return esito;
    }

    //endregion


    //region Mappers

    private _mappaColoreLegno(nomeLegno: string): number{
        return {
            bianco: 0xdbdbdb,
            limpido: 0xe4ecff,
            nero: 0x3d3d3d,
            nocechiaro: 0xa55d2f,
            rosachiaro: 0xffdcce,
        }[nomeLegno.replaceAll(' ', '').toLowerCase()] || 0xffffff;
    }

    private _mappaColorePlex(nomePlex: string): number{
        return {
            bianco: 0xf0f0f0,
            nero: 0x141414,
        }[nomePlex.replaceAll(' ', '').toLowerCase()] || 0xffffff;
    }

    private _mappaColoreColore(nomePlex: string): number{
        return {
            bianco: 0xf0f0f0,
            nero: 0x141414,
        }[nomePlex.replaceAll(' ', '').toLowerCase()] || 0xffffff;
    }

    //endregion

    private _recuperaPartiRatio(): [number, number]{
        const aspectRatio = MangerRenderizzatoreModelliHelper.calcolaAspectRatio(
            this._configurazioneSezione,
            this._configurazioneModello,
            this._modelloConfigurabileContenitore,
            this.modalitaVisualizzazioneAlternativa
        );

        return aspectRatio.split('/').map(value => Math.ceil(parseFloat(value.trim()))) as [number, number];
    }

    public resetVisualizzazioni(updateElemento: boolean = true) {
        this._visualizzaSezioneSuperficieInModifica = false;
        this._datiSezioneCollegati = false;
        this.materialeSezioneSuperfice.map = undefined;
        this.materialeSezioneSuperfice.needsUpdate = true;
        this.elementoSelezionatoPerModifica = false;
        super.resetVisualizzazioni(updateElemento);
    }

    //region Beam

    public get uuidCollisionList(): string[] {
        return this.configurazioneSezione.nomeSezioneRiferimentoInformazioni.length === 0 ? [
            this.renderObject?.uuid,
            this._sezioneSuperficie?.uuid
        ] : [];
    }

    public get materialeSezioneSuperfice(): MeshLambertMaterial{
        return this._sezioneSuperficie.material as MeshLambertMaterial;
    }

    public get materialeRenderObject(): MeshLambertMaterial{
        return (this.renderObject as Mesh).material as MeshLambertMaterial;
    }

    public get configurazioneSezione(): ConfigurazioneSezioneInterface {
        return this._configurazioneSezione;
    }

    public get configurazioneModello(): ConfigurazioneModelloInterface {
        return this._configurazioneModello;
    }

    public get modelloConfigurabileContenitore(): ModelloConfigurabile {
        return this._modelloConfigurabileContenitore;
    }

    public get sezioneSuperficie(): Mesh {
        return this._sezioneSuperficie;
    }

    public get elementoSelezionatoPerModifica(): boolean {
        return this._elementoSelezionatoPerModifica;
    }

    public set elementoSelezionatoPerModifica(value: boolean) {
        this.movimentoPivot.visualizzaMeshPivot = value;
        if(this.renderObject)
            (this.renderObject.userData as RenderObjectUserData).outlineSelected = value
        this._elementoSelezionatoPerModifica = value;
    }

    public get visualizzaSezioneSuperficieInModifica(): boolean {
        return this._visualizzaSezioneSuperficieInModifica;
    }

    public set visualizzaSezioneSuperficieInModifica(value: boolean) {
        this._visualizzaSezioneSuperficieInModifica = value;
    }

    public set datiSezioneCollegati(datiSezioneCollegati: boolean){
        this._datiSezioneCollegati = datiSezioneCollegati;
    }

    public get datiSezioneCollegati(): boolean {
        return this._datiSezioneCollegati;
    }

    public get materialeInput(): MeshBasicMaterial {
        return this._materialeInput;
    }

    public set materialeInput(value: MeshBasicMaterial) {
        this._materialeInput = value;
    }

    //endregion
}
