import {StorageData} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import React from "react";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import Loader from "../../Core/Loader/Loader";

export interface SingoloSelettoreImmagineProps {
    immagine: StorageData | StorageData[],
    order: number
    escluso?: boolean,
    selezionato?: boolean,
    onClick?: () => void,
    overlaySelettore?: React.ReactElement;
}

export default class SingoloSelettoreImmagine extends React.Component<SingoloSelettoreImmagineProps, {}>{
    public get isMultiplaImmagine(): boolean{
        return Array.isArray(this.props.immagine);
    }

    public get immaginePrincipale(): StorageData{
        return this.isMultiplaImmagine ? (this.props.immagine as StorageData[])[0] : this.props.immagine as StorageData;
    }

    public get immagineSecondaria(): StorageData{
        let esito = undefined;

        if(this.isMultiplaImmagine)
            esito = (this.props.immagine as StorageData[])[1];

        return esito;
    }



    public render() {
        return (
            <IfContainer
                condition={!!this.immaginePrincipale.url()}
                elseComponent={
                    <Loader/>
                }>
                <div
                    className={`SingoloSelettoreImmagineContainer ${this.isMultiplaImmagine && 'multiploContenuto'}`}
                    onClick={() => {this.props.onClick && this.props.onClick()}}
                    style={{order: `${this.props.order}`}}>
                    <img
                        alt={this.immaginePrincipale.name}
                        className={`SingoloSelettoreImmagine ${this.props.escluso && 'escluso'} ${this.props.selezionato && 'selezionato'}`}
                        loading={"lazy"}
                        src={this.immaginePrincipale.url()}/>
                    {
                        this.isMultiplaImmagine &&
                        <img
                            alt={this.immagineSecondaria.name}
                            className={`SingoloSelettoreImmagine ${this.props.escluso && 'escluso'}`}
                            loading={"lazy"}
                            src={this.immagineSecondaria.url()}/>
                    }
                    {this.props.overlaySelettore && this.props.overlaySelettore}
                </div>
            </IfContainer>
        );
    }
}
